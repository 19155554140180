import React from "react";
import "./style.css";

export default function SeasonBanner() {
  return (
    <div id="seasonBanner" className="gradientBox">
      Open for dev work! <br/>- Summer 2023
    </div>
  );
}
